.login_page_appbar_dev_hide {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #f9fafb;
  padding-right: 8px;
  position: fixed;
  right: 0;
  left: 0;
  display: none;
}

.login_page_outer_wrap {
  background: #f9fafb;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  height: calc(100vh);
}

@media only screen and (max-width: 768px) {
  .login_page_outer_wrap {
    background: #f9fafb;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    padding: 20px 20px 40px 20px;
  }
  .login_page_outer_wrap .login_page_img_outer {
    margin-top: 20px;
  }
  .login_page_outer_wrap .login_page_img_outer {
    width: 90vw;
  }
  .login_page_outer_wrap .login_page_img_outer img {
    width: 90vw;
  }
  .login_inner_container {
    min-width: 90vw !important;
  }
  .login_outer_container {
    height: auto !important;
  }
}

.login_page_outer_wrap .login_page_img_outer {
  width: 35%;
  display: flex;
  justify-content: center;
}
.login_page_outer_wrap .login_page_img_outer img {
  height: 506px;
}

.login_page_outer_wrap .login_outer_container {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9fafb;
}
.login_inner_container {
  min-width: 440px;
  /* box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  box-shadow: 0px 4px 8px 0 #dadce0;
  padding: 24px;
  /* border-radius: 12px; */
  border: 1px solid var(--border);
}

.login_inner_container .zino_inputBox {
  border: 1px solid var(--border);
  border-radius: 0px;
}

.login_inner_container h1 {
  margin-bottom: 16px;
  color: #ed563b;
  font-weight: 700;
}
.login_inner_container label {
  font-size: var(--small-font);
  margin-top: 4px;
}
.login_field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.login_field button {
  margin-top: 20px;
  padding: 12px 0;
  font-weight: 600;
  letter-spacing: 1px;
}

.zino_label {
  font-weight: 500;
  font-size: var(--small-font);
  color: var(--font-color);
}

.forget_password_container {
  display: flex;
  justify-content: flex-end;
}

.forget_password_label {
  font-size: var(--small-font);
  font-weight: 500;
  color: #3498db;
  font-size: 14px;
  cursor: pointer;
}
.forget_password_label:hover {
  color: #58b0eb;
}
.forget_password_label.otp_submit {
  font-size: 16px;
  width: fit-content;
  margin: auto;
  /* color: #2160ec; */
  color: var(--primary-color);
  margin-top: 16px;
  text-decoration: underline;
}

.forget_password_label.otp_submit:hover {
  color: #4d7de7;
}

.errorMessage {
  font-size: var(--small-font);
  font-weight: 600;
  color: #ed563b;
}

.login_button_container {
  display: flex;
  padding-top: 10px;
  justify-content: space-around;
}

.login_button_container button {
  font-size: var(--small-font);
  padding: 8px;
  border: 1px solid;
}

.message_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.back_container {
  display: flex;
  align-items: center;
  font-size: var(--small-font);
  font-weight: 600;
  gap: 8px;
}

.back_container:hover *,
.login_back_btn:hover svg {
  color: var(--primary-color);
  fill: var(--primary-color) !important;
}

.zino_link {
  color: blue;
  cursor: pointer;
  text-decoration: none;
  margin-left: 5px;
}

.email_highlight {
  font-size: 15px;
  font-weight: bold;
}

.message_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.go-to-login-page {
  cursor: pointer;
  position: fixed;
  top: 12px;
  left: 16px;
  color: #667085;
}

.switch-mobile-desktop {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch-mobile-desktop > .material-symbols-outlined {
  position: absolute;
  font-size: 20px;
  cursor: pointer;
}
.switch-mobile-desktop > .switch-mobile-slider {
  right: 7px;
  top: 7px;
}
.switch-mobile-desktop > .switch-desktop-slider {
  left: 7px;
  top: 7px;
}

.switch-mobile-desktop-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-mobile-desktop-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eeeeee;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-mobile-desktop-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-mobile-desktop-input:checked + .switch-mobile-desktop-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.switch-mobile-desktop-input ~ .switch-desktop-slider {
  color: var(--primary-color);
}
.switch-mobile-desktop-input ~ .switch-mobile-slider {
  color: #ccc;
}
.switch-mobile-desktop-input:checked ~ .switch-desktop-slider {
  color: #ccc;
}
.switch-mobile-desktop-input:checked ~ .switch-mobile-slider {
  color: var(--primary-color);
}

.mobile_login_page_outer_wrap {
  background: #f9fafb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 36px);
}

.mobile_login_page_outer_wrap.otp-login {
  height: calc(100vh - 36px);
}

.mobile_login_page_outer_wrap .login_outer_container {
  width: 100%;
}

.mobile_login_page_outer_wrap .login_page_img_outer {
  display: flex;
  justify-content: center;
  height: calc(100vh - 500px);
}

.mobile_login_page_outer_wrap.otp-login .login_page_img_outer {
  height: calc(100vh - 240px);
}

.mobile_login_page_outer_wrap .login_page_img_outer img {
  width: 100%;
}

.mobile_login_page_outer_wrap .login_inner_container {
  min-width: 340px;
  width: 100%;
  box-shadow: none;
  border: none;
}

/*install popup styles*/
/* bottom: 5%; */
.install-prompt {
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, 0px);
  display: flex;
  flex-direction: column;
  background-color: var(--bg-plain);
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 99;
  color: var(--font-color);
  min-width: 280px;
  max-width: 332px;
}

.install-prompt p {
  font-size: 12px;
  margin: 0 0 8px;
}

.install-prompt-button-container {
  display: flex;
  gap: 8px;
}

.ios-install-prompt {
  position: fixed;
  bottom: 5%;
  left: 5%;
  right: 5%;
  display: flex;
  align-self: center;
  justify-self: center;
  gap: 8px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.ios-install-prompt p {
  font-size: 12px;
  margin: 0 0 8px;
}

.ios-install-prompt .delete-icon {
  color: rgb(249, 86, 86);
}
.ios-install-prompt-button-container {
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
}

.popup_button {
  padding: 4px 12px;
  min-height: fit-content;
}

.cognito_btn {
  border: 1px solid var(--border);
  border-radius: var(--button-radius);
  padding: 12px;
  display: flex;
  gap: 8px;
  height: var(--button-height);
  font-size: var(--small-font);
  background-color: #f3f4f6;
}

.cognito_btn:hover {
  background-color: #e5e7eb;
}

.more_btn {
  display: flex;
  margin-top: 5px;
  width: 100%;
  color: var(--primary);
  background-color: transparent;
  border: none;
  width: fit-content;
  color: rgb(58, 24, 144);
  display: flex;
  align-items: center;
}

.microsoft_auth_container {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.microsoft_auth_signin,
.microsoft_auth_signup {
  border: 1px solid var(--border);
  border-radius: var(--button-radius);
  padding: 12px;
  display: flex;
  gap: 8px;
  height: var(--button-height);
  font-size: var(--small-font);
  background-color: #f3f4f6;
  width: 100%;
}

.login_form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}