.pageView_container {
  margin: 0 !important;
  height: 100%;
  padding: 20px 12px;
  min-height: 40vh;
}

.error404_page_outerBody {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-content: center;
  background-image: url(../Images/shap.svg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #4a1fb8;
}

.pageView_item_outer {
  padding: 8px 20px;
}

.error404_page_outerBody > h1 {
  background-color: #fff;
  text-transform: uppercase;
  text-align: center;
  padding: 25px;
  border-radius: 50px;
  margin-bottom: 25px;
  cursor: none;
}

.error404_page_outerBody > span {
  color: white;
  padding: 25px;
  /* background-color: #4a1fb8; */
  text-transform: uppercase;
  text-align: center;
}

.spinner_outerBox {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-content: center;
}

.userDashboardItemListOutBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px;
  width: fit-content;
}

.userDashboardItemListOutBox > .db_app_itemCard {
  cursor: pointer;
  width: 250px;
  background: #ffffff;
  border: 1px solid #e4e7ec;
  /* box-shadow: 4px 4px 8px 4px rgba(228, 231, 236, 0.3); */
  border-radius: 8px;
  padding: 20px;
}

.userDashboardItemListOutBox * {
  box-sizing: border-box;
}

.userDashboardItemListOutBox .db_app_itemCard .db_app_itemCardTitle {
  font-style: normal;
  font-weight: 600;
  font-size: var(--small-font);
  color: var(--font-color);
  margin: 10px 0 0 0;
  width: 208px;
  overflow: hidden;
  word-wrap: break-word;
}

.userDashboardItemListOutBox .db_app_itemCard img {
  width: 48px;
  height: 48px;
}

.entity_history_table {
  height: calc(100vh - 75px);
  width: 96%;
  margin: auto;
}

.entity_history_table p {
  margin: 0;
}

.entity_history_table .MuiDataGrid-selectedRowCount {
  visibility: hidden !important;
}

.app_entry_dashboard .app_entry_dashboard_container > .app_entry_dashboard_item > .app_entry_dashboard_itemS {
  min-height: 100px;
  padding: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid blue;
}

.app_entry_dashboard_itemS {
  /* height: 100% !important; */
  width: 100% !important;
}

.app_entry_dashboard .app_entry_dashboard_container > .app_entry_dashboard_item {
  padding: 0 5px;
}

.app_entry_dashboard .app_entry_dashboard_container {
  margin-top: 15px;
}

.new_activity_buttonOutBox {
  position: relative;
}

.new_activity_buttonOutBox .new_activity_button {
  position: absolute;
  top: -42px;
  right: 24px;
  color: #4a1fb8;
  display: flex;
  gap: 6px;
}

.new_activity_buttonOutBox .new_activity_button button {
  color: #4a1fb8;
  display: flex;
  gap: 6px;
}

@media only screen and (max-width: 600px) {
  .new_activity_buttonOutBox .new_activity_button {
    position: relative;
    top: 0;
    right: 0;
  }
}

.applist_outerbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  padding: 36px;
}

.applist_item {
  width: 256px;
  height: 163px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #fff;
  padding: 20px;
  cursor: pointer;
  border: 1px solid #e4e7ec;
  box-shadow: 4px 4px 8px 4px rgba(228, 231, 236, 0.3);
  overflow: hidden;
}

.applist_item_disable * {
  opacity: 0.5;
}

.app_name_outer {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .applist_item p {
  height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.logo_container {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo_style {
  width: 48px;
  height: 48px;
  object-fit: contain;
  border-radius: 50%;
}

.app_details .app_title {
  font-size: var(--small-font);
  font-weight: 600;
}

.app_details .app_description {
  font-size: var(--small-font);
  font-weight: 400;
}
/* blocks  */

.static_field_blocks {
  background: #fff;
  border-radius: var(--block-radius);
  box-shadow: var(--shadow);
  padding: var(--block-padding);
  border: 1px solid var(--border);
}

.mobile_applist_outerbox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.mobile_applist_outerbox .applist_item {
  align-items: center;
  background-color: #fff;
  border: 1px solid #e4e7ec;
  border-radius: 4px;
  box-shadow: #f2f4f7cc;
  display: flex;
  flex-direction: row;
  font-size: small;
  gap: 8px;
  height: 56px;
  padding: 12px;
  width: 100%;
}
.mobile_applist_outerbox .applist_item.applist_item_disable {
  background: #00000008;
  border: 1px solid #0000001f;
  box-shadow: none;
  color: #00000042;
  outline: none;
  pointer-events: none;
}
.mobile_applist_outerbox .logo_container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.mobile_applist_outerbox .logo_container .logo_style {
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 50%;
}

/*----------------------- Excel upload table section  start -----------------------------*/
.excel_upload_table_wrapper {
  width: 100%;
  padding: 20px;
}
.excel_searchBox_outer {
  display: flex;
  align-items: center;
  gap: 20px;
}
.excel_searchBox_outer input {
  margin-top: 0px;
}
.excel_upload_table_outer {
  width: 100%;
  overflow: auto;
  box-shadow: var(--shadow);
  background: #fff;
  border-radius: 8px;
  padding: 20px 0;
  margin-top: 20px;
}
.excel_upload_table {
  width: 100%;
  border: 1px solid var(--border);
  overflow: hidden auto;
  border-collapse: collapse; /* This ensures no space between table cells */
}
.excel_upload_table tbody tr td {
  border-bottom: 1px solid var(--border) !important;
  font-size: var(--small-font);
  color: var(--font-color);
  padding: 16px 12px;
}
.excel_upload_table tbody tr:nth-child(1) {
  background: var(--secondary-color);
  font-weight: 600;
}

.record_details_table {
  min-width: fit-content;
  max-width: 100%;
  border: 1px solid var(--border);
  overflow: hidden auto;
  border-collapse: collapse;
  overflow: auto;
}
.record_details_table thead tr td,
.record_details_table thead tr th {
  border-bottom: 1px solid var(--border) !important;
  font-size: var(--small-font);
  color: var(--font-color);
  padding: 16px 12px;
  background-color: var(--secondary-color);
  font-weight: 600;
}
.record_details_table tbody tr td {
  border-bottom: 1px solid var(--border) !important;
  font-size: var(--small-font);
  color: var(--font-color);
  padding: 16px 12px;
}

.app_config_tabview_renderer_outerbox {
  min-height: calc(100vh - 62px);
  border: 1px solid var(--border);
  box-shadow: var(--shadow);
}
.app_config_tabview_body {
  margin-top: 20px;
  height: calc(100% - 200px);
  overflow: hidden;
}
.app_config_downloads {
  border: 1px solid var(--border);
  height: calc(100vh - 246px);
  overflow: auto;
}
.app_config_master_data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}

.no_access_outer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
