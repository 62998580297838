.app_navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 32px;
  border-bottom: 1px solid var(--nav-border);
  background: var(--nav-bg-color);
  top: 0;
  left: 0;
  right: 0;
  z-index: 7;
}

.app_navbar_logo_wrap {
  display: flex;
  align-items: center;
  gap: 40px;
}
.nav_menu_alignment {
  display: flex;
  align-items: center;
}

.zinologo {
  cursor: pointer;
  display: flex;
  gap: 10px;
}
.zinologo > .zinologo_text {
  display: flex;
  font-weight: 600;
  letter-spacing: 0.5px;
  white-space: nowrap;
  margin-right: 1.5rem;
  color: var(--nav-item-color);
}
.zinologo img {
  height: 36px;
  width: auto;
}
.top_menu_outerBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 0px;
  margin: 0px 8px;
}
.top_menu_item {
  text-decoration: none;
  color: var(--nav-item-color);
  display: flex;
  align-items: center;
  font-size: var(--small-font);
  font-weight: 500;
  cursor: pointer;
  text-transform: capitalize;
  padding: 0px 12px;
  white-space: nowrap;
  height: 40px;
  margin: auto;
  border: 2px solid transparent;
  border-radius: var(--menu-item-radius);
  display: flex;
  gap: 8px;
}

.top_menu_item_page_active,
.top_menu_item_page:hover,
.top_menu_item_dropdown:hover {
  background: var(--menu-item-bg);
  color: var(--nav-item-active);
  border-bottom: 2px solid var(--nav-item-border-bottom);
}

.menu_dropdown_item_wrap .expand_more_icon svg {
  fill: var(--nav-item-color);
}
.top_menu_item_dropdown:hover .menu_item_name .expand_more_icon svg {
  fill: var(--nav-item-active);
}
.desktopview_top_menu .top_menu_item:hover svg {
  fill: #fff;
}

.top_menu_item_outer {
  position: relative;
  width: 100%;
}
.top_menu_item.top_menu_item_dropdown.active {
  background: var(--menu-item-bg);
  color: var(--nav-item-active);
}
.top_menu_item.top_menu_item_dropdown.active .expand_more_icon svg {
  fill: var(--nav-item-active);
}

.top_menu_dropdown_itemOuterBox .top_menu_item_page {
  border-top: 0px;
  text-transform: capitalize;
  margin: 0px;
  padding: 10px;
  font-size: var(--small-font);
  font-weight: 500;
  width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
/* 
.top_menu_dropdown_itemOuterBox .top_menu_item_page:hover {
  background: var(--menu-item-bg);
  color: var(--nav-item-active);
} */

.top_menu_dropdown_itemOuterBox {
  /* display: none; */
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #667085;
  z-index: 5;
  transition: all 0.3s ease-in-out;
  background: var(--body-bg);
  border: 1px solid var(--border);
  padding: var(--p-8);
}
.top_menu_item_outer .top_menu_dropdown_itemOuterBox {
  background: var(--nav-dropdown-bg);
}
.top_menu_dropdown_itemOuterBox:hover {
  display: flex;
}

.top_menu_item_outer:focus .top_menu_dropdown_itemOuterBox {
  display: flex;
}
.top_menu_item .menu_item_name {
  width: 100%;
  display: flex;
  align-items: center;
}
.top_menu_item .menu_item_name .menu_dropdown_item_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}

/* desktop menu bar or top menubar */

.desktopview_top_menu .top_menu_item_outer .top_menu_dropdown_itemOuterBox {
  position: absolute;
  top: 40px;
  right: 0px;
  border: 1px solid var(--nav-dropdown-border);
  box-shadow: var(--shadow);
  border-radius: var(--nav-dropdown-radius);
}
.top_menu_item > span {
  height: 24px;
  display: flex;
  align-items: center;
}

.top_menu_item_dropdown {
  display: flex;
  align-items: center;
  height: 40px;
  margin: auto;
}

.desktopview_top_menu .top_menu_item_dropdown .menu_item_name {
  /* padding: 8px; */
  max-width: 164px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.desktopview_top_menu .top_menu_item_dropdown .menu_item_name .menu_item_text {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* left sidebar styles */
.top_menu_item_dropdown .menu_item_name {
  padding: 0px;
  gap: 8px;
}

.top_menu_item_dropdown:focus .expand_more_icon {
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out;
}

.top_menu_item_dropdown:focus > .top_menu_dropdown_itemOuterBox {
  display: block;
}
.top_menu_item:focus > .top_menu_dropdown_itemOuterBox {
  display: block !important;
}

.top_menu_dropdown_itemOuterBox .top_menu_item_page {
  font-size: var(--small-font);
  cursor: pointer;
  text-transform: capitalize;
  padding: 10px 20px;
  border-bottom: 2px solid transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
}

.mobile_view_dropdown .top_menu_item_dropdown {
  align-items: flex-start;
  flex-direction: column;
}

.mobile_view_dropdown .top_menu_item_dropdown > .top_menu_dropdown_itemOuterBox {
  position: relative;
  top: 10px;
}

.nav_icon_outer {
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.nav_icon_outer svg {
  width: 20px;
  height: 20px;
  fill: var(--nav-item-color);
}

.nav_icon_outer > .nav_icon_count {
  font-size: 10px;
  position: absolute;
  right: 0;
  top: 0;
  /* background: var(--menu-item-bg); */
  /* color: var(--menu-item-bg); */
  color: var(--nav-item-color);
  padding: 1px 5px;
  border-radius: 50%;
}

.nav_icon_outer .nav_menu_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 20px !important;
  color: var(--nav-item-color);
  font-weight: 400;
}

.nav_icon_outer:hover {
  background: var(--menu-item-bg);
}
.nav_icon_outer:hover .nav_icon_count {
  color: var(--nav-item-active);
  display: none;
}

.nav_icon_outer .nav_icon_count.nav_icon_style {
  background: #f04438;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: unset;
}
.nav_icon_outer:hover .nav_icon_count.nav_icon_style {
  background: #f04438;
  color: #fff;
}
.nav_icon_outer:hover svg,
.nav_icon_outer:hover .nav_menu_icon {
  fill: var(--nav-item-active);
  color: var(--nav-item-active);
}
.notification_container .nav_icon_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.notification_container:hover .nav_icon_text {
  color: var(--nav-item-active) !important;
}

.app_navbar_breadcrumbs {
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.app_navbar_breadcrumbs > .app_navbar_breadcrumbItem {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #5e5e5e;
  font-size: var(--small-font);
  font-weight: 600;
}

.app_navbar_breadcrumbs > .app_navbar_breadcrumbItem > .breadcrumbItem_name {
  cursor: pointer;
}

.app_navbar_breadcrumbs > .app_navbar_breadcrumbItem > .breadcrumbItem_space {
  cursor: context-menu;
}

.app_navbar_breadcrumbs > .app_navbar_breadcrumbItem:last-child > .breadcrumbItem_name {
  color: #667085;
  cursor: context-menu;
}

.profile_dropdown_container {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.profile_dropdown {
  position: relative;
}

.profile_popover {
  position: absolute;
  top: 32px;
  right: 4px;
  width: 100px;
  background-color: var(--nav-dropdown-bg);
  color: var(--font-color);
  border: 1px solid gray;
  box-shadow: var(--shadow);
  z-index: 1;
  display: none;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
  border: 1px solid var(--nav-dropdown-border);
  border-radius: var(--block-radius);
  min-width: 220px;
  font-weight: 500;
  font-size: var(--small-font);
  color: #10182b;
  padding: 8px;
}

.profile_dropdown:hover .profile_popover {
  display: block;
}

.profile_popover div {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  color: var(--nav-dropdown-color);
  font-weight: 500;
}

.profile_popover div:hover {
  background: var(--menu-item-bg);
  color: var(--nav-dropdown-active);
}

.cursor-pointer {
  cursor: pointer;
}

.profile_popover div span {
  margin-left: 8px;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.top_navbar_buttonBox {
  gap: 16px;
  position: absolute;
}

.top_navbar_buttonBox > button {
  height: 32px;
}

.mobileview_top_menu {
  display: none;
}

.mobileview_top_menu_popup {
  background: var(--nav-bg-color);
  width: 280px;
  height: 100vh;
  min-width: 280px;
  height: 100vh;
  left: -300px;
  border-right: 1px solid var(--border);
  transition: all 0.3s ease-in-out;
  padding: 0px 20px;
  position: fixed;
  z-index: 7;
  top: 0px;
}

.mobileview_top_menu_popupopen {
  left: 0;
}

.mobileview_top_menu_popup .mobileview_top_menu_content {
  height: 100%;
  overflow: auto;
}

.mobileview_top_menu_close {
  padding: 16px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0px 0px 4px rgba(150, 150, 151, 0.5); */
  border-bottom: 1px solid rgba(150, 150, 151, 0.5);
}
.navBar_menuItems_outer {
  height: calc(100vh - 240px);
  overflow: auto;
}
.navBar_menuItems_outer::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.mobileview_top_menu_footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  /* box-shadow: 0px 0px 4px rgba(150, 150, 151, 0.5); */
  border-top: 1px solid var(--border);
  padding: 16px;
  background: #fff;
  color: #667085;
  font-size: var(--small-font) !important;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  background-color: var(--nav-bg-color);
}

.mobileview_top_menu_footer .notification_container,
.mobileview_top_menu_footer .profile_dropdown {
  display: flex;
  align-items: center;
  gap: 10px;
}
.mobileview_top_menu_footer .profile_popover {
  left: 0px;
  min-width: 100% !important;
  top: -265px;
}

.mobileview_top_menu_footer > div {
  cursor: pointer;
}
.notification_container .navbar_notification,
.navbar_icon_text {
  color: var(--nav-item-color);
}
.mobileview_top_menu_footer .notification_container:hover .nav_icon_outer svg,
.mobileview_top_menu_footer .notification_container:hover .navbar_notification {
  fill: var(--nav-item-active);
  color: var(--nav-item-active);
}
.mobileview_top_menu_footer .profile_dropdown:hover .navbar_user_icon svg,
.mobileview_top_menu_footer .profile_dropdown:hover .navbar_user_profile {
  fill: var(--nav-item-active);
  color: var(--nav-item-active);
}

.mobileview_top_menu_footer .left_navbar_chat:hover .nav_icon_outer svg {
  fill: var(--nav-item-active);
  color: var(--nav-item-active);
}
.mobileview_top_menu_footer .nav_icon_outer:hover {
  background: var(--menu-item-bg);
}

.mobileview_top_menu_footer > div:hover {
  background: var(--menu-item-bg);
  color: var(--nav-item-active);
}

.mobileview_top_menu_popup .top_menu_outerBox {
  flex-direction: column;
  margin-top: 8px;
}
.mobileview_top_menu_popup .top_menu_outerBox > a {
  width: 100%;
}
.mobileview_top_menu_popup .top_menu_dropdown_itemOuterBox .top_menu_item_page {
  padding: 12px;
}
.mobileview_top_menu_popup .top_menu_dropdown_itemOuterBox .top_menu_item_page .dropdown_nav_item {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media all and (max-width: 900px) {
  .desktopview_top_menu {
    display: none;
  }
  .mobileview_top_menu {
    display: block;
  }
}

/* change password popup*/

.password_outer_container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: hsla(0, 0%, 85%, 0.4);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password_inner_container {
  background-color: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  width: 30%;
}

.password_header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  border-bottom: 1px solid #e4e7ec;
}

.password_header_container p {
  font-size: var(--small-font);
  font-weight: 600;
}

.password_header_container span {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.password_body_container {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  gap: 12px;
}

.password_input_container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.password_input_container .password_input_label {
  font-size: var(--small-font);
  font-weight: 400;
}

.password_input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid black;
}

.errorMessage {
  font-size: var(--small-font);
  font-weight: 600;
  color: #ed563b;
}

.nav_icons {
  display: flex;
}

/* added them for zadmin menu items */
.zadmin_menu_item {
  text-decoration: none;
  color: var(--font-color);
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-transform: capitalize;
  padding: 0px 12px;
  white-space: nowrap;
  height: 40px;
  margin: auto;
  border: 2px solid transparent;
  border-radius: var(--button-radius);
  display: flex;
  gap: 8px;
}

.zadmin_menu_item:hover {
  background: var(--menu-item-bg);
  color: var(--nav-item-active);
  border-bottom: 2px solid var(--nav-item-border-bottom);
}

/* end of the changes made */

.nav_module_item_list {
  position: absolute;
  top: 32px;
  right: 4px;
  background-color: var(--nav-dropdown-bg);
  color: var(--font-color);
  border: 1px solid gray;
  box-shadow: var(--shadow);
  z-index: 1;
  display: none;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
  border: 1px solid var(--nav-dropdown-border);
  border-radius: var(--block-radius);
  min-width: 220px;
  font-weight: 500;
  font-size: var(--small-font);
  color: #10182b;
  padding: 8px;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
}

.profile_dropdown:hover .nav_module_item_list {
  display: grid;
}

.nav_module_item_list .nav_module_item {
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  width: 120px;
}
.nav_module_item_list .nav_module_item:hover {
  background: var(--menu-item-bg);
  color: var(--nav-dropdown-active);
}

.nav_module_item_list .nav_module_item span {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobileview_top_menu_footer .nav_module_item_list {
  left: 0px;
  min-width: 100% !important;
  top: -265px;
}

.notification_button {
  width: 40px;
  height: 40px;
  border-radius: 40px !important;
  box-shadow: 0px 4px 8px 0px #e4e7ec80;
}
