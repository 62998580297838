.popup_loading_overlay_box {
  visibility: visible;
  opacity: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #7d7d7d2e;
  overflow-y: scroll;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_loading_overlay_box .popup_loading_box {
  height: 50px;
  width: 50px;
  animation: popupLoadingFrame 1s infinite;
}

.popup_loading_overlay {
  visibility: visible;
  opacity: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #7d7d7d2e;
  overflow-y: scroll;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_loading_overlay .popup_loading_box {
  height: 50px;
  width: 50px;
  animation: popupLoadingFrame 1s infinite;
}

@keyframes popupLoadingFrame {
  0% {
    border: 2px solid transparent;
  }
  25% {
    border: 2px solid transparent;
    border-top: 2px solid var(--primary-color);
  }
  50% {
    border: 2px solid transparent;
    border-right: 2px solid var(--primary-color);
  }
  75% {
    border: 2px solid transparent;
    border-bottom: 2px solid var(--primary-color);
  }
  100% {
    border: 2px solid transparent;
    border-left: 2px solid var(--primary-color);
  }
}
