@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.builder_btn_secondary {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--button-height);
  gap: 8px;
  border-radius: 4px;
  font-weight: 400;
  font-size: var(--small-font);
  line-height: 18px;
  background: #ffffff;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.builder_btn_secondary:hover {
  background: var(--primary-color);
  color: #ffffff;
}

.zino_btn_outline_primary.login {
  border: 1px solid #4a1fb8;
  color: #4a1fb8;
}
.zino_btn_outline_primary.login:hover {
  background: rgb(58, 24, 144);
  color: #fff;
}

.welcomePage {
  overflow: hidden;
}

.welcomePage_top_navbar_outerBox {
  align-items: center;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(228, 231, 236, 0.5);
  display: flex;
  height: 64px;
  justify-content: space-between;
  left: 0;
  padding: 0 30px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1;
}

.welcomePage_top_navbar_outerBox button {
  padding: 8px 12px;
}

.welcomePage_top_navbar_outerBox .welcomePage_top_navbar_logoBox {
  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: flex-start;
}

.welcomePage_top_navbar_outerBox .welcomePage_top_navbar_logoBox img {
  height: 36px;
}

.welcomePage_top_navbar_outerBox .welcomePage_top_navbar_logoBox span {
  color: #ed563b;
  font-size: var(--small-font);
  font-weight: 600;
  line-height: 15px;
  margin-left: 5px;
  text-transform: uppercase;
}

.welcomePage_hero_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  padding: 64px 80px 0;
  width: 100%;
}

.welcomePage_hero_section img {
  width: 80%;
}

.welcomePage_hero_section span {
  color: #000000;
  font-size: 1.5rem;
}

.welcomePage_hero_section p {
  color: #ed563b;
  font-weight: 700;
  font-size: 2rem;
}
