* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins;
}

:root {
  --primary-color: #8a58f6;
  --secondary-color: #f9fafb;
  --highlight-color: #f3f4f6;
  --font-color: #10182b;
  --light-font-color: #667085;
  --body-bg: #f5f5f9;
  --bg-plain: #fff;
  --bg-active: #00000014;
  --border: #e4e7ec;
  --shadow: 0px 4px 8px 0px rgba(228, 231, 236, 0.3);
  --hover: #f9fafb;
  --hover-font: #fff;
  --hover-bg: #f9fafb;
  --hover-border: #667085;
  --menu-item-bg: #ae8bfa;
  --button-radius: 4px;

  --primary-btn-active: #956ee9;
  --primary-btn-activeClr: #fff;
  --danger-btn-active: #eb2727;
  --danger-btn-activeClr: #fff;
  --button-height: 40px;

  /* navbar background (specfic changes) */
  --nav-bg-color: #fff;
  --nav-item-color: #10182b;
  --nav-item-active: #fff;
  --nav-item-border-bottom: transparent;
  --nav-dropdown-bg: #fff;
  --nav-dropdown-color: #10182b;
  --nav-dropdown-active: #fff;
  --nav-dropdown-active-bg: #ae8bfa;
  --nav-dropdown-border: #e4e7ec;
  --nav-dropdown-radius: 4px;
  --p-8: 8px;
  --nav-border: #e4e7ec;

  /* tiles card  */
  --tiles-card-bg: #f4f3ff;
  --tiles-card-border: #d9d6fe;
  --tiles-font-color: #10182b;
  --tiles-font-size: 16px;
  --tiles-count-size: 20px;

  /* blocks */
  --block-shadow: 0px 4px 8px 0px rgba(228, 231, 236, 0.3);
  --block-radius: 8px;

  /* scrollbar */
  --scrollbar-thumb: #d9d9d9;
  --scrollbar-thumb-dark: #bfc0c1;
  --scrollbar-track: #eee;

  --page-scrollbar: #f1f1f1;
  --page-scrollbar-thumb: #888;
  --page-scrollbar-thumb-dark: #555;

  --danger-color: #f04438;

  /* font-size */
  --small-font: 14px;
  --medium-font: 16px;
  --large-font: 20px;

  --small-icon: 24px;

  --block-padding: 20px;

  /* racord view table color */
  --rv-table-bg: #fafafa;
  --rv-table-header: #f9fafb;
  --table-row-bg-select: #fafafa;

  /* form input tags */
  --form-bg: #fff;
  --form-input-height: 48px;
  --inputBox-border: #d0d5dd;
  --inputBox-border-active: #a2acbf;
  --disable-bg: #fafafa;
  --disable-color: #98a2b3;
}

body {
  font-size: var(--small-font);
  min-height: 100vh;
  height: 100%;
  background: #f9fafb;
}

.text_nocopy {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

button {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  outline: none;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--button-radius);
  cursor: pointer;
}
.material-symbols-outlined {
  font-size: var(--small-icon);
}

.zino_btn_primary,
.zino_btn_danger,
.zino_btn_outline_primary {
  border: 1px solid var(--primary-color);
  border-radius: var(--button-radius);
  padding: 12px;
  display: flex;
  gap: 8px;
  height: var(--button-height);
  font-size: var(--small-font);
}

.zino_icon_button {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 0;
  outline: 0;
  height: 64px;
  background-color: transparent;
}

.zino_btn_primary {
  color: #ffffff;
  background: var(--primary-color);
}

.zino_btn_primary:hover {
  background: var(--primary-btn-active);
  border: 1px solid var(--primary-btn-active);
  color: var(--primary-btn-activeClr);
}

.zino_btn_outline_primary {
  background: #fff;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.zino_btn_outline_primary:hover {
  background: var(--primary-color);
  color: var(--hover-font);
}

.zino_btn_danger {
  color: #ffffff;
  background: var(--danger-color);
  border: 1px solid var(--danger-btn-active);
}

.zino_btn_danger:hover {
  background: var(--danger-btn-active);
  color: var(--danger-btn-activeClr);
}

.zino_btn_outline_danger {
  background: #fff;
  color: var(--danger-color);
  border: 1px solid var(--danger-color);
}

.zino_btn_outline_danger:hover {
  background: var(--danger-color);
  color: var(--hover-font);
}

.zino_btn_secondary {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background: var(--hover-font);
  border-radius: var(--button-radius);
}

.zino_btn_secondary:hover {
  background: var(--menu-item-bg);
  color: #fff;
}

.zino_btn_outline_secondary {
  border: 1px solid #e4e7ec;
  background: var(--bg-plain);
  color: var(--secondary-color);
}

.zino_btn_outline_secondary:hover {
  background: var(--secondary-color);
  color: #ffffff;
}
.arrow_btn {
  border-radius: var(--button-radius);
  width: 32px;
  height: 32px;
  padding: 8px;
}

.zino_btn_disabled,
.zino_btn_disabled:hover,
button:disabled {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none;
  background: rgba(0, 0, 0, 0.03) !important;
  pointer-events: none;
  outline: none;
  border-radius: var(--button-radius);
}
.dropdown_action_field_outerBox1.zino_btn_disabled .dropdown_action_field_exp svg path {
  fill: rgba(0, 0, 0, 0.26);
}

.zino_btn_primary.login {
  background: #4a1fb8 !important;
}
.zino_btn_primary.login:hover {
  background: rgb(58, 24, 144) !important;
  color: #fff !important;
}

.app_circle_btn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-plain);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 50%;
}
.app_circle_btn:hover {
  background: var(--primary-color);
  color: #fff;
}
.recordview_table_wraper {
  overflow: inherit;
  width: 100%;
  position: relative;
}

.circular_progress_svg {
  animation: progress 30s infinite;
}

table thead tr th,
table tbody tr td {
  text-align: start !important;
}

@keyframes progress {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(3600deg);
  }
}

.zino_inputBox {
  align-items: center;
  background: #fff !important;
  border: 1px solid #d0d5dd;
  border-radius: var(--button-radius);
  display: flex;
  flex-direction: row;
  font-size: var(--small-font);
  font-weight: 400;
  gap: 10px;
  height: 52px;
  line-height: 20px;
  outline: none;
  padding: 16px 10px;
  margin-top: 4px;
  width: 100%;
}
.zino_inputBox:focus-visible {
  border: 1px solid #10182b;
}
.zino_inputBox:hover {
  border: 1px solid #10182b;
}

.zino_renderer_loader_box {
  display: grid;
  place-content: center;
  height: 100vh;
  width: 100%;
}

.zino_renderer_loader {
  width: 48px;
  height: 48px;
  border: 3px solid #fff;
  border-radius: 50%;
  position: relative;
  transform: rotate(45deg);
  box-sizing: border-box;
}
.zino_renderer_loader::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  inset: -10px;
  border-radius: 50%;
  border: 3px solid #ff3d00;
  animation: prixClipFix 1s infinite linear;
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.zino_renderer_line_loader,
.zino_renderer_line_loader:before,
.zino_renderer_line_loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.zino_renderer_line_loader {
  color: var(--primary-color);
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.zino_renderer_line_loader:before,
.zino_renderer_line_loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.zino_renderer_line_loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.zino_renderer_line_loader:after {
  left: 3.5em;
}
.app_icon_outer {
  background: #fff;
  border: 1px solid var(--border);
  padding: 4px;
  border-radius: var(--button-radius);
}
.app_icon_outer .material-symbols-outlined {
  margin-right: 0px !important;
  font-size: 20px;
  color: var(--font-color);
}
.app_icon_outer:hover,
.app_icon_outer:hover .material-symbols-outlined {
  background: var(--primary-color);
  color: #fff;
}

@keyframes bblFadInOut {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* common classes */
.app_label,
.app_label_key {
  font-size: var(--small-font);
  color: var(--font-color);
  font-weight: 400;
}
.default_text {
  font-size: var(--small-font);
  color: var(--font-color);
  font-weight: 400;
}
.app_label_key {
  font-weight: 600;
}
.app_status_text {
  font-size: 20px;
  font-weight: 600;
  color: var(--font-color);
}
.app_text_bold {
  font-size: 20px;
  columns: var(--font-color);
  font-weight: 600;
}
.app_secondary_text {
  font-size: var(--small-font);
  color: var(--font-color);
  font-weight: 500;
  margin-top: 8px;
}

.choose_file_outer {
  border: 1px solid var(--border);
  margin: auto;
  padding: 10px 20px 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background: #fff;
  box-shadow: var(--shadow);
  border-radius: var(--button-radius);
  color: var(--font-color);
  font-size: 1em;
}

.app_sub_heading {
  color: var(--font-color);
  font-size: 16px;
}
.app_block_heading {
  color: var(--font-color);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}

.app_collapsible_dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  cursor: pointer;
}
.app_collapsible_dropdown .app_collapsible_dropdown_header {
  font-size: 18px;
  font-weight: 600;
}
.app_collapsible_dropdown .app_collapsible_expand_icon svg {
  height: 24px;
  width: 20px;
  fill: var(--font-color);
}
.app_collapsible_dropdown .app_collapsible_expand_icon svg:hover {
  fill: var(--primary-color);
}
.app_form_item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.app_form_input_wrap {
  display: flex;
  flex-direction: column;
}

.app_collapse_body_items {
  border-top: 1px solid var(--border);
  padding: 12px 0px;
}
.app_collapse_sub_heading {
  padding: 12px;
  font-size: 16px;
  font-weight: 600;
}
.app_collapse_status {
  font-size: var(--small-font);
  color: var(--font-color);
  text-align: center;
}
.app_collapse_completed_outer {
  display: flex;
  align-content: center;
  gap: 32px;
  padding: 12px;
  min-width: 60%;
  max-width: 100%;
  overflow: auto;
}
.app_collapse_completed_details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid var(--border);
  padding: 4px 64px 4px 4px;
  position: relative;
}
.app_collapse_completed_action {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px;
}
.app_collapse_completed_actionBtn {
  position: absolute;
  right: 0px;
  top: 0px;
  display: none;
}
.app_collapse_completed_details:hover .app_collapse_completed_actionBtn {
  display: block;
}
.collapse_table_details tbody tr td {
  padding: 4px 8px;
  white-space: nowrap;
}

/* app action buttons */
.app_action_btn {
  width: 24px;
  height: 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-plain);
  color: var(--light-font-color);
  border: 1px solid var(--border);
  border-radius: 4px;
  cursor: pointer;
}
.app_action_btn:hover {
  background: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
}
.app_action_btn .material-symbols-outlined {
  font-size: 16px;
  color: inherit;
}
.app_action_btn.delete {
  color: var(--danger-color);
  background: var(--bg-plain);
}
.app_action_btn.delete:hover {
  background: var(--danger-color);
  color: #fff;
  border: 1px solid var(--danger-color);
}

/* --------------------meterial ui styles start ----------------------------*/
.MuiOutlinedInput-root {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  overflow: auto;
}
.css-w66kx-MuiChip-root,
.css-1kqsah9 {
  font-size: var(--small-font) !important;
}
.css-6od3lo-MuiChip-label {
  font-size: 12px;
}
/* --------------------meterial ui styles end ----------------------------*/

.selected_chips_outer .selected_chip_items {
  display: flex;
  align-items: center;
  gap: 8px;
  background: var(--highlight-color);
  font-size: var(--small-font);
  color: var(--font-color);
  border-radius: 8px;
  padding: 4px;
}
.selected_chip_content {
  min-width: 32px;
  max-width: 124px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* app scrollbar start */

/* body scrollbar  */
body::-webkit-scrollbar {
  width: 0px;
  height: 14px;
}

body::-webkit-scrollbar-track {
  background-color: var(--page-scrollbar);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--page-scrollbar-thumb);
  border-radius: 2px;
  cursor: pointer;
}
body::-webkit-scrollbar-thumb:hover {
  background-color: var(--page-scrollbar-thumb-dark);
}

::-webkit-scrollbar {
  width: 0px;
  height: 14px;
}
/* default scrollbar for all the blocks */
::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 2px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-dark);
}
/* app scrollbar end */

/* app form items */
.mb_8 {
  margin-bottom: 8px !important;
}
.mb_20 {
  margin-bottom: 20px !important;
}
.mb_none {
  margin-bottom: 0px !important;
}
.ml_auto {
  margin-left: auto;
}
.p_20 {
  padding: 20px !important;
}
.p_8 {
  padding: 8px !important;
}
.p_12 {
  padding: 12px !important;
}
.w_100 {
  width: 100% !important;
}
.w_fit {
  width: fit-content;
}

.d_flex {
  display: flex;
  align-items: center;
}
.d_flex_column {
  display: flex;
  flex-direction: column;
}
.space_between {
  justify-content: space-between;
}
.g_4 {
  gap: 4px;
}
.g_8 {
  gap: 8px;
}
.g_12 {
  gap: 12px;
}
.g_20 {
  gap: 20px;
}
.app_label {
  font-size: var(--small-font);
  color: var(--font-color);
}
.app_dropdown_outer {
  padding: 0px 8px;
  border: 1px solid var(--border);
  background: var(--bg-plain);
  width: fit-content;
}
.app_dropdown_outer .app_inputBox,
.app_dropdown_outer .app_inputBox:hover {
  border: none;
}
.app_dropdown_outer .app_inputBox {
  width: 100%;
}
.app_inputBox {
  height: 40px;
  border: 1px solid var(--border);
  font-size: var(--small-font);
  color: var(--font-color);
  padding: 0px 8px;
}
.app_inputBox:hover,
.app_inputBox:focus-visible,
.app_dropdown_outer:hover,
.app_dropdown_outer:focus-visible {
  border: 1px solid var(--font-color);
}
.app_inputBox {
  outline: none;
}

.tab_droupdown > .form_field_outer_box .did-floating-default-select {
  border: none;
  border-bottom: 1px solid var(--border);
}
.tab_droupdown > .form_field_outer_box .did-floating-default-select:focus-visible {
  border: none !important;
  border-bottom: 1px solid var(--primary-color) !important;
}

.primary_text_btn {
  color: #8a58f6;
  font-weight: 400;
  cursor: pointer;
}

.table_link {
  color: #3388ff;
  text-decoration-line: none;
}

.table_link:hover {
  text-decoration-line: underline;
}
